<h2 class="mt-4">Operation's</h2>
<div class="table-responsive">

  <table class="table table-striped table-sm" *ngIf="data">
    <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Date</th>
      <th>Value</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of data.data">
      <td>{{item.no}}</td>
      <td>{{item.name}}</td>
      <td>{{item.date | date}}</td>
      <td>{{item.value | currency}}</td>
    </tr>
    </tbody>
    <tfoot *ngIf="data.sum">
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td>{{data.sum.value | currency}}</td>
      </tr>
    </tfoot>
  </table>

</div>
